import { CheckoutData } from "@queries/createCheckoutSession";
import { create } from "zustand";

type Actions = {
  setProduct: (product: string | OlynCheckout.Product) => void;
  reset: () => void;
  getSession: (type: CheckoutData["mode"]) => CheckoutData | null;
};

const parseProduct = (product: string | null): OlynCheckout.Product | null => {
  if (!product) return null;

  try {
    return JSON.parse(product as string)[0] as OlynCheckout.Product;
  } catch (e) {
    console.log("Unable to convert checkout cart from session storage", e);

    return null;
  }
};

export const useProductStore = create<
  { product: OlynCheckout.Product | null } & Actions
>((set, get) => ({
  product: null,
  setProduct: (product) => {
    set((state) => {
      let newProduct = product;

      if (typeof product !== "string") {
        newProduct = JSON.stringify(product);
      }

      if (newProduct === JSON.stringify(state.product)) {
        return state;
      }

      return {
        product: typeof product !== "string" ? product : parseProduct(product),
      };
    });
  },
  reset: () => {
    set({ product: null });
  },
  getSession: (type) => {
    const { product } = get();

    if (product === null) return null;

    return {
      mode: type,
      items: [
        {
          quantity: 1,
          price: type === "tokenization" ? "0" : String(product.price.value),
          currency: product.price.currency,
          metadata: {
            content_id: product.content_id,
            cover_img: product.cover_img,
            content_url: product.content_url,
            title: product.title,
            company_splits: product.company_splits,
          },
        },
      ],
    };
  },
}));
