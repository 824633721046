import {
  CheckoutSessionResponse,
} from "@queries/createCheckoutSession";
import {
  queryOptions,
  UndefinedInitialDataOptions,
  useQuery,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";
import { AxiosError } from "axios";

const getPaymentSession = async ({ session_id }: { session_id: string }) => {
  const res = await client.get<CheckoutSessionResponse>(
    `checkout/sessions/${session_id}`,
  );

  return res.data;
};

export const checkoutSessionKey = ["checkout", "session"];


export const getPaymentSessionByIdOptions = (
  query: {
    session_id: string;
  },
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        CheckoutSessionResponse,
        AxiosError,
        CheckoutSessionResponse["data"]
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  queryOptions({
    queryKey: [...checkoutSessionKey, query],
    queryFn: () => getPaymentSession(query),
    select: (data) => data.data,
    gcTime: Infinity,
    staleTime: Infinity,
    ...options,
  });

export const useGetPaymentSessionById = (
  query: {
    session_id: string;
  },
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        CheckoutSessionResponse,
        AxiosError,
        CheckoutSessionResponse["data"]
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) => useQuery(getPaymentSessionByIdOptions(query, options));
