const checkSessionStorage = () => {
  if (typeof window !== "undefined" && window.sessionStorage) {
    return true;
  }

  return false;
};

export const enum SessionStorageItems {
  "verification_auth_code" = "verification_auth_code",
  "ga_key" = "ga_key",
  "gtm_key" = "gtm_key",
}

export type SessionStorageItem = keyof typeof SessionStorageItems;

export function setItem(name: SessionStorageItem, value: string): void;
export function setItem(name: string, value: string): void;
export function setItem(
  name: SessionStorageItem | string,
  value: string,
): void {
  try {
    if (checkSessionStorage()) {
      sessionStorage.setItem(name, value);
    }
  } catch (e) {
    console.error(
      "Error happened during setting item to sessionStorage",
      e,
      name,
      value,
    );
  }
}

export function getItem(name: SessionStorageItem): string | null;
export function getItem(name: string): string | null;
export function getItem(name: string): string | null {
  try {
    if (checkSessionStorage()) {
      return sessionStorage.getItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to sessionStorage",
      e,
      name,
    );
  }

  return null;
}

export const getAll = (): object | null => {
  try {
    if (checkSessionStorage()) {
      return { ...sessionStorage };
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to sessionStorage",
      e,
      name,
    );
  }

  return null;
};

export const deleteItem = (name: SessionStorageItem): void => {
  try {
    if (checkSessionStorage()) {
      sessionStorage.removeItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to sessionStorage",
      e,
      name,
    );
  }
};
